<div *ngIf="tripDetails.hubs.length >= 1">
  <div class="tb-container">
    <!-- TRIP BANNER -->
    <section
      id="itinerary-banner"
      *ngIf="tripImages.length >= imagesLength"
      class=""
    >
      <div class="position-relative">
        <div class="w-100">
          <div class="d-flex">
            <div class="flexwidth_50">
              <app-image
                class="itinerary-items image_curve"
                [image]="tripImages[0]['img']"
                [options]="{ width: 675, height: 390 }"
                [lazyLoad]="false"
              ></app-image>
            </div>
            <div
              class="ml-3 flexwidth_50 d-lg-block d-xl-block d-none"
              *ngIf="tripImages.length >= 2"
            >
              <app-image
                class="itinerary-items image_curve"
                [image]="tripImages[1]['img']"
                [lazyLoad]="false"
                [options]="{ width: 675, height: 390 }"
              ></app-image>
              <!-- <div class="itinerary-masonry">
                
                <app-image
                  class="itinerary-items image_curve"
                  [image]="tripImages[2]['img']"
                  [lazyLoad]="false"
                  [options]="{ width: 335, height: 191 }"
                ></app-image>
                <app-image
                  class="itinerary-items image_curve"
                  [image]="tripImages[3]['img']"
                  [lazyLoad]="false"
                  [options]="{ width: 335, height: 191 }"
                ></app-image>
                <app-image
                  class="itinerary-items image_curve"
                  [image]="tripImages[4]['img']"
                  [lazyLoad]="false"
                  [options]="{ width: 335, height: 191 }"
                ></app-image>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- TRIP BANNER -->

    <div class="d-flex justify-content-end">
      <div class="iti-more-options" (click)="share()">
        <i class="fa fa-share-alt"></i><br />
        Share
      </div>
      <div
        class="iti-more-options"
        (click)="updateTripStatus(content)"
        *ngIf="editable"
      >
        <i class="fa fa-paper-plane"></i><br />
        {{ tripStatus == "public" ? "Unpublish" : "Publish" }}
      </div>
      <div
        class="iti-more-options"
        (click)="deletetrip(deleteContent)"
        *ngIf="editable"
      >
        <i class="fa fa-trash-alt"></i><br />
        Delete
      </div>
    </div>

    <div class="d-flex">
      <div
        class="underlined-heading line-break mr-3 active underline left"
        *ngIf="tripTitle != ''"
      >
        {{ tripTitle }}
      </div>
    </div>

    <div *ngIf="tripStory != ''" class="content-body">
      {{ tripStory }}
    </div>
    <div class="trip_tags" *ngIf="tags.length>0">
      <div class="spacer-1"></div>
      <span *ngFor="let tag of tags" class="tags-pill">{{tag['tag']}} : {{tag['rating']}}</span>
    </div>
    <div class="spacer-3"></div>
    <div
      class="title-heading d-inline-block d-lg-block margin-row"
      style="display: none !important"
    >
      Route Overview
    </div>

    <div
      class="route_overview"
      *ngIf="tripDetails.hubs.length >= 1"
      style="display: none"
    >
      <div class="d-flex flexrow-reverse mb-lg-4 mb-4 align-items-start">
        <div class="flex-40 route-left-section">
          <div class="route-timeline">
            <div *ngFor="let hub of tripDetails.hubs" class="hubs_hold">
              <app-hub *ngIf="hub['hub_type'] == 'hub'" [hub]="hub"></app-hub>
              <app-hub-mode
                *ngIf="hub['hub_type'] == 'mode'"
                [mode]="hub"
              ></app-hub-mode>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Itienrary -->
  <div *ngIf="tripDays.length >= 1">
    <div class="tb-container">
      <div class="title-heading d-inline-block d-lg-block margin-row">
        Day Wise Plan
      </div>
    </div>

    <div class="itinerary-bg">
      <div
        class="swipe-button swipe-left d-lg-block d-xl-block d-none"
        (click)="scrollDays('left')"
      >
        <i class="fa fa-chevron-left"></i>
      </div>
      <div
        class="swipe-button swipe-right d-lg-block d-xl-block d-none"
        (click)="scrollDays('right')"
      >
        <i class="fa fa-chevron-right"></i>
      </div>
      <div class="days-container" #scrollBox>
        <div
          class="days-holder"
          *ngFor="let day of tripDays; let dayCount = index"
        >
          <!-- Day heading-->
          <div
            class="text-center d-flex day_wise_dates align-items-center position-relative"
            [id]="'dayNumber' + day['details']['dayNumber']"
            #dayElements
          >
            <div
              class="content-heading itinerary_heading"
              (click)="daysDropDownToggle()"
            >
              <span>Day {{ day["details"]["dayNumber"] }}</span>
              <span class="day_divider">|</span>
              <span class="mr-2">{{
                day["details"]["date_time"] | date : "MMM dd"
              }}</span>
              <!-- <span class="mr-1">
                <i class="fa fa-chevron-down mr-1"></i>
              </span> -->
              <div class="day-dropdown" *ngIf="daysDropDown">
                <ul class="">
                  <li
                    *ngFor="let day of tripDays; let i = index"
                    (click)="scrollToDay(day['details']['dayNumber'])"
                  >
                    Day {{ day["details"]["dayNumber"] }}
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="content-body d-block ml-3"
              (click)="showDayMap(dayCount, day['details']['dayNumber'])"
            >
              <i class="fa fa-map-marker-alt primary-text-color"></i>
            </div>
          </div>

          <div class="itinerary-cwrapper">
            <div *ngFor="let item of day['days']">
              <!-- REFERENCE BLOCK -->
              <reference-block
                *ngIf="item['type'] == 'reference'"
              ></reference-block>
              <!-- COMMUTE -->
              <app-day-commute
                *ngIf="item['type'] == 'mode'"
                [commute]="item"
              ></app-day-commute>

              <!-- Place -->
              <day-places
                *ngIf="item['type'] == 'place'"
                [place]="item"
                [tripUrl]="tripurl"
              ></day-places>

              <!-- FREE TIME -->
              <free-time *ngIf="item['type'] == 'free'"></free-time>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tb-container">
    <div *ngIf="visaInformation.length>0">
      <div ngbAccordion>
        <div ngbAccordionItem >
          <div ngbAccordionHeader >
            <button ngbAccordionButton class="accordion-collapse-button content-body">
              <div>Visa</div>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-container *ngFor="let visaInfo of visaInformation">
                <div >
                  {{visaInfo?.country}} : {{visaInfo?.requirement}}
                </div>
              </ng-container>
                
            </div>
          </div>
        </div>
      </div>
      </div>
      <div ngbAccordion>
        <div ngbAccordionItem >
          <div ngbAccordionHeader >
            <button ngbAccordionButton class="accordion-collapse-button content-body">
              <div>Emergency Contacts</div>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-container *ngFor="let contact of emergencyContacts">
                <div >
                  {{contact?.name}} : {{contact?.phone_number}}
                </div>
              </ng-container>
                
            </div>
          </div>
        </div>
      </div>
      <div ngbAccordion>
      <div ngbAccordionItem *ngFor="let itemDetails of additionalDetails">
        <div ngbAccordionHeader >
          <button ngbAccordionButton class="accordion-collapse-button content-body">
            <div>{{itemDetails['heading']}}</div>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div *ngFor="let item of itemDetails.items">
                {{item}}
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      
      
      </div>
  </div>
</div>
<!-- {{ tripDays | json }} -->
<!-- LOADING ANIMATION -->
<!-- <div
  class="itinerary_full_loading {{ loadingText.cssclass }}"
  *ngIf="firstLoading"
>
  <div class="loading_update">
    <div class="loading_text">{{ loadingText.text }}</div>
  </div>
</div> 

-->

<div
  class="loading_animation {{ loadingText.cssclass }}"
  *ngIf="loadingItinerary"
>
  <img
    src="/assets/images/itinerary_animation.gif"
    width="100px"
    class="itinerary_image"
  />
  <!-- <div class="clouds">
    <div class="cloud">☁️</div>
    <div class="cloud">☁️</div>
    <div class="cloud">☁️</div>
    <div class="cloud">☁️</div>
  </div>
  <div class="car">🚗</div>
  <div class="road"></div> -->
  <div class="footer-loading-text">{{ loadingText.text }}</div>
</div>

<ng-template #content let-modal>
  <div class="modal-body">
    Do you like to {{ tripStatus == "public" ? "Unpublish" : "Publish" }} this
    trip?
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn secondary-button"
      (click)="modal.close('no')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn primary-button"
      (click)="modal.close('publish')"
    >
      {{ tripStatus == "public" ? "Unpublish" : "Publish" }}
    </button>
  </div>
</ng-template>

<ng-template #deleteContent let-modal>
  <div class="modal-body">
    This is irrversible process, are you sure you want to delete?
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn secondary-button"
      (click)="modal.close('no')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn primary-button"
      (click)="modal.close('delete')"
    >
      Delete
    </button>
  </div>
</ng-template>
